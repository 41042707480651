<template>
  <div v-if="showSignModal" class="signFormModal">
    <section @click="closeSignModal" />
    <van-form @submit="onSubmitSignForm">
      <van-field v-model="signForm.signTypeName" name="signTypeName" label="考勤分类" readonly />
      <van-field v-model="signForm.studentsCnName" type="textarea" autosize name="学员信息" label="学员信息" readonly />
      <van-field name="checkbox">
        <template #input>
          <van-checkbox v-model="signForm.sendWxMsg" shape="square" :disabled="!signForm.canSendWxMsg">发送消息到学员绑定的微信</van-checkbox>
        </template>
      </van-field>
      <div style="margin: 16px;">
        <van-button block type="primary" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import eduApi from '@/api/edu'

// 消息场景：签到
const MSG_SCENE_SIGN_IN = 1
// 消息场景：签退
const MSG_SCENE_SIGN_OUT = 2

// 签到
const SIGN_IN = 1
// 签退
const SIGN_OUT = 0
export default {
  props: {
    clazzId: {
      type: String,
      default: -1,
      require: true
    }
  },
  data() {
    return {
      showSignModal: false,
      signForm: {
        signType: 1,
        signTypeName: '',
        studentId: null,
        clazzId: this.clazzId,
        sendWxMsg: false,
        canSendWxMsg: false // 是否可发送微信消息
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    openSignIn(students, canSendSignInMsg) {
      this.signForm.signType = SIGN_IN
      this.signForm.signTypeName = '签到'
      this.signForm.clazzId = this.clazzId
      this.signForm.canSendWxMsg = canSendSignInMsg
      this.signForm.sendWxMsg = false
      this.signForm.studentIds = []

      let studentsCnNameArr = []
      for (let i = 0; i < students.length; i++) {
        let student = students[i]
        let bounded = student.boundedWxUserList && student.boundedWxUserList.length > 0 ? true : false
        studentsCnNameArr.push(student.cnName + (bounded ? '[wx]' : ''))
        this.signForm.studentIds.push(student.id)
      }
      this.signForm.studentsCnName = studentsCnNameArr.join('\n')

      // 过滤重复studentId
      this.signForm.studentIds = Array.from(new Set(this.signForm.studentIds))

      // this.signForm.studentId = item.id
      // this.signForm.studentCnName = item.cnName
      // this.signForm.boundedWxUsers = ''

      this.showSignModal = true
    },
    openSignOut(students, canSendSignOutMsg) {
      this.signForm.signType = SIGN_OUT
      this.signForm.signTypeName = '签退'
      this.signForm.clazzId = this.clazzId
      this.signForm.canSendWxMsg = canSendSignOutMsg
      this.signForm.sendWxMsg = false

      this.signForm.studentIds = []

      let studentsCnNameArr = []
      for (let i = 0; i < students.length; i++) {
        let student = students[i]
        let bounded = student.boundedWxUserList && student.boundedWxUserList.length > 0 ? true : false
        studentsCnNameArr.push(student.cnName + (bounded ? '[wx]' : ''))
        this.signForm.studentIds.push(student.id)
      }
      this.signForm.studentsCnName = studentsCnNameArr.join('\n')

      // 过滤重复studentId
      this.signForm.studentIds = Array.from(new Set(this.signForm.studentIds))

      this.showSignModal = true
    },
    onSubmitSignForm(values) {
      eduApi.batchSaveSignInOutLog(this.signForm).then(res => {
        if (res.succ) {
          this.showSignModal = false
          this.$toast('添加成功')
        } else {
          this.$toast(res.message)
        }
      })
    },
    closeSignModal() {
      this.showSignModal = false
    }
  }
}
</script>
<style lang="less" scoped>
.signFormModal {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
  section {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .van-form {
    background-color: #fff;
    width: 90%;
    position: fixed;
    top: 50%;
    margin-top: -40%;
    left: 50%;
    margin-left: -45%;
    padding: 6px;
    box-sizing: border-box;
  }
}
</style>
