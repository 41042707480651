<template>
  <van-tabs v-model="active" sticky>
    <van-tab title="学员列表">
      <StudentList ref="studentList" />
    </van-tab>
    <van-tab title="上课提醒">
      <CourseRemindList ref="courseRemindList" />
    </van-tab>
  </van-tabs>
</template>
<script>
import StudentList from './components/StudentList.vue'
import CourseRemindList from './components/CourseRemindList.vue'
export default {
  components: {
    StudentList,
    CourseRemindList
  },
  data() {
    return {
      active: 0,
      appId: '',
      clazz: {
        id: null,
        cnName: ''
      }
    }
  },
  created() {
    this.appId = this.$route.params.appId
    this.clazz.id = this.$route.params.id
    this.clazz.cnName = this.$route.query.cnName
    document.title = '【' + this.clazz.cnName + '】信息'
  },
  mounted() {},
  methods: {
    initData() {
      console.log('initData')
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .van-sticky {
  border-bottom: 1px solid #ddd;
}
</style>
