<template>
  <div v-if="showLessonLogModal" class="lessonLogFormModal">
    <section @click="closeLessonLogModal" />
    <van-form @submit="onSubmitLessonLogForm">
      <van-field v-model="lessonLogForm.lessonTime" name="lessonTime" label="上课时间" readonly />
      <van-field name="stepper" label="课时消耗">
        <template #input>
          <van-stepper v-model="lessonLogForm.consumeClassHour" step="0.5" min="0.5" max="9" />
        </template>
      </van-field>

      <van-field v-model="lessonLogForm.studentsCnName" type="textarea" autosize name="学员信息" label="学员信息" readonly />

      <van-field name="checkbox">
        <template #input>
          <van-checkbox v-model="lessonLogForm.sendWxMsg" shape="square" :disabled="!lessonLogForm.canSendWxMsg">发送消息到学员绑定的微信</van-checkbox>
        </template>
      </van-field>
      <div style="margin: 16px;">
        <van-button block type="primary" native-type="submit">提交</van-button>
      </div>
    </van-form>

  </div>
</template>
<script>
import eduApi from '@/api/edu'
// 消息场景：课时消耗
const MSG_SCENE_CLASS_HOUR_CONSUME = 6
export default {
  props: {
    clazzId: {
      type: String,
      default: -1,
      require: true
    }
  },
  data() {
    return {
      showLessonLogModal: false,
      lessonLogForm: {
        studentIds: [],
        clazzId: null,
        lessonTimes: 1,
        lessonTime: '',
        boundedWxUsers: '',
        sendWxMsg: false,
        classHourDescn: '',
        canSendWxMsg: false // 是否可发送微信消息
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    async open(students, canSendClassHourConsumeMsg) {
      this.lessonLogForm.studentIds = []

      let studentsCnNameArr = []
      for (let i = 0; i < students.length; i++) {
        let student = students[i]
        let bounded = student.boundedWxUserList && student.boundedWxUserList.length > 0 ? true : false
        studentsCnNameArr.push(student.cnName + (bounded ? '[wx]' : ''))

        this.lessonLogForm.studentIds.push(student.id)
      }
      this.lessonLogForm.studentsCnName = studentsCnNameArr.join('\n')
      // 过滤重复studentId
      this.lessonLogForm.studentIds = Array.from(new Set(this.lessonLogForm.studentIds))
      this.lessonLogForm.clazzId = this.clazzId

      this.lessonLogForm.consumeClassHour = 1
      this.lessonLogForm.canSendWxMsg = canSendClassHourConsumeMsg
      this.lessonLogForm.sendWxMsg = false

      let lessonLogResp = await eduApi.beforeBatchAddLessonLog(this.clazzId, this.lessonLogForm.studentId)

      this.lessonLogForm.lessonTime = lessonLogResp.data.lessonTime

      // // 设置课时描述信息
      // let classHour = lessonLogResp.data.classHour
      // if (classHour) {
      //   this.lessonLogForm.classHourId = classHour.id
      //   if (classHour.accountType === 0) {
      //     this.classHourDescn = '[课时]' + classHour.usedClassHour + '/' + classHour.totalClassHour
      //   } else {
      //     this.classHourDescn = '[截止日]' + classHour.expiryDate
      //   }
      // } else {
      //   this.classHourDescn = '未设置课时账户'
      // }

      this.showLessonLogModal = true
    },
    onSubmitLessonLogForm(values) {
      eduApi
        .batchSaveLessonLog(this.lessonLogForm)
        .then(res => {
          if (res.succ) {
            this.showLessonLogModal = false
            this.$toast('添加成功')
            this.$emit('reloadStudentList')
          } else {
            this.$toast(res.message)
          }
        })
        .catch(e => {})
    },
    onConfirmClassHourPicker(value) {
      this.lessonLogForm.classHourId = value.id
      this.lessonLogForm.classHourCourseTitle = value.text
    },
    closeLessonLogModal() {
      this.showLessonLogModal = false
    }
  }
}
</script>
<style lang="less" scoped>
.lessonLogFormModal {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
  section {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .van-form {
    background-color: #fff;
    width: 90%;
    position: fixed;
    top: 50%;
    margin-top: -70%;
    left: 50%;
    margin-left: -45%;
    padding: 6px;
    box-sizing: border-box;
  }
}
</style>
