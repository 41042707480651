<template>
  <div v-if="showLessonLogModal" class="lessonLogFormModal">
    <section @click="closeLessonLogModal" />
    <van-form @submit="onSubmitLessonLogForm">
      <van-field v-model="lessonLogForm.studentCnName" name="studentCnName" label="学员姓名" readonly />
      <van-field v-model="lessonLogForm.lessonTime" name="lessonTime" label="上课时间" readonly />
      <van-field name="stepper" label="当前课次">
        <template #input>
          <van-stepper v-model="lessonLogForm.lessonTimes" step="1" min="1" max="999" />
        </template>
      </van-field>
      <van-field readonly label="课时账户" :value="classHourDescn" />
      <van-field name="stepper" label="课时消耗">
        <template #input>
          <van-stepper v-model="lessonLogForm.consumeClassHour" step="0.5" min="0.5" max="9" />
        </template>
      </van-field>
      <van-field v-model="lessonLogForm.remark" type="textarea" autosize name="remark" label="讲师评语" rows="3" />
      <van-field v-model="lessonLogForm.boundedWxUsers" type="textarea" autosize name="绑定微信" label="绑定微信" readonly rows="1" />
      <van-field name="checkbox">
        <template #input>
          <van-checkbox v-model="lessonLogForm.sendWxMsg" shape="square" :disabled="!lessonLogForm.canSendWxMsg">发送消息到学员绑定的微信</van-checkbox>
        </template>
      </van-field>
      <div style="margin: 16px;">
        <van-button block type="primary" native-type="submit">提交</van-button>
      </div>
    </van-form>

  </div>
</template>
<script>
import eduApi from '@/api/edu'
// 消息场景：课时消耗
const MSG_SCENE_CLASS_HOUR_CONSUME = 6
export default {
  props: {
    clazzId: {
      type: String,
      default: -1,
      require: true
    }
  },
  data() {
    return {
      showLessonLogModal: false,
      lessonLogForm: {
        studentId: null,
        clazzId: null,
        lessonTimes: 1,
        lessonTime: '',
        boundedWxUsers: '',
        sendWxMsg: false,
        classHourDescn: '',
        canSendWxMsg: false // 是否可发送微信消息
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    async open(item, canSendClassHourConsumeMsg) {
      this.lessonLogForm.studentId = item.id
      this.lessonLogForm.clazzId = this.clazzId
      this.lessonLogForm.studentCnName = item.cnName
      this.lessonLogForm.classHourId = ''
      this.lessonLogForm.classHourCourseTitle = ''
      this.lessonLogForm.consumeClassHour = 1
      this.lessonLogForm.canSendWxMsg = canSendClassHourConsumeMsg && item.boundedWxUserList && item.boundedWxUserList.length > 0
      this.lessonLogForm.sendWxMsg = false

      let lessonLogResp = await eduApi.beforeAddLessonLog(this.clazzId, this.lessonLogForm.studentId)

      let lessonLogItem = lessonLogResp.data.item
      this.lessonLogForm.lessonTimes = lessonLogItem.lessonTimes
      this.lessonLogForm.lessonTime = lessonLogItem.lessonTime

      // 设置课时描述信息
      let classHour = lessonLogResp.data.classHour
      if (classHour) {
        this.lessonLogForm.classHourId = classHour.id
        if (classHour.accountType === 0) {
          this.classHourDescn = '[课时]' + classHour.usedClassHour + '/' + classHour.totalClassHour + ' [校准]' + classHour.preSubtractClassHour
        } else {
          this.classHourDescn = '[截止日]' + classHour.expiryDate
        }
      } else {
        this.classHourDescn = '未设置课时账户'
      }

      // 设置绑定的微信人员信息
      if (item.boundedWxUserList && item.boundedWxUserList.length > 0) {
        let nicknames = []
        for (let i = 0; i < item.boundedWxUserList.length; i++) {
          nicknames.push(item.boundedWxUserList[i].nickname)
        }
        this.lessonLogForm.boundedWxUsers = nicknames.join('\n')
      } else {
        this.lessonLogForm.boundedWxUsers = '暂未绑定微信'
      }
      this.showLessonLogModal = true
    },
    onSubmitLessonLogForm(values) {
      if (this.lessonLogForm.classHourId === '') {
        this.$toast('请先设置学员的课时账户')
        return false
      }
      eduApi.saveLessonLog(this.lessonLogForm).then(res => {
        if (res.succ) {
          this.showLessonLogModal = false
          this.$toast('添加成功')
          console.log('emit reloadStudent list')
          this.$emit('reloadStudentList')
        } else {
          this.$toast(res.message)
        }
      })
    },
    onConfirmClassHourPicker(value) {
      this.lessonLogForm.classHourId = value.id
      this.lessonLogForm.classHourCourseTitle = value.text
    },
    closeLessonLogModal() {
      this.showLessonLogModal = false
    }
  }
}
</script>
<style lang="less" scoped>
.lessonLogFormModal {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
  section {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .van-form {
    background-color: #fff;
    width: 90%;
    position: fixed;
    top: 50%;
    margin-top: -70%;
    left: 50%;
    margin-left: -45%;
    padding: 6px;
    box-sizing: border-box;
  }
}
</style>
