<template>
  <div v-if="showPopModal" class="popModal">
    <section @click="closeSignModal" />
    <div class="detail-wrapper">
      <div class="student-info">
        <img :src="student.photo" class="avatar" />
        <span class="cnname">{{ student.cnName }}</span>
      </div>
      <div class="qrcode-wrapper">
        <img :src="qrCodeSrc" />
        <div class="tip">请学员家长扫码绑定学员信息</div>
      </div>
      <div class="bounded-wxuser">
        <ul>
          <li v-for="(item,idx) in student.boundedWxUserList" :key="idx">
            <img class="mini-avatar" :src="item.avatar" /><span class="wxuser-nickname">{{ item.nickname }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import eduApi from '@/api/edu'

export default {
  data() {
    return {
      appId: '',
      showPopModal: false,
      url: '',
      qrCodeSrc: '',
      student: null
    }
  },
  created() {
    this.appId = this.$route.params.appId
  },
  mounted() {},
  methods: {
    open(item) {
      // 获取二维码信息
      console.log(item.id)
      this.student = item
      this.url = encodeURIComponent(process.env.VUE_APP_BASE_URL + `/${this.appId}/edu/student/${item.id}/boundWxUser`)
      // this.url = encodeURIComponent(process.env.VUE_APP_BASE_URL + `/${this.appId}/studentBoundWxUser?studentId=${item.id}`)
      this.qrCodeSrc = `/api/qrCode?url=${this.url}`
      this.showPopModal = true
    },

    closeSignModal() {
      this.showPopModal = false
    }
  }
}
</script>
<style lang="less" scoped>
.popModal {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
  section {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .detail-wrapper {
    background-color: #fff;
    position: absolute;
    top: 50%;
    margin-top: -60%;
    width: 80%;
    left: 50%;
    margin-left: -40%;
    padding: 6px;
    box-sizing: border-box;
    .student-info {
      display: flex;
      align-items: center;
      padding: 0.2rem 1rem 0rem;
      justify-content: center;
      .avatar {
        display: block;
        width: 2rem;
        height: 2rem;
        border: 1px solid #ccc;
      }
      .cnname {
        font-size: 0.6rem;
        margin-left: 0.2rem;
      }
    }
    .qrcode-wrapper {
      text-align: center;
      img {
        display: inline-block;
        width: 80%;
        height: 80%;
      }
      .tip {
        color: red;
        text-align: center;
      }
    }
    .bounded-wxuser {
      margin-top: 0.4rem;
      li {
        display: flex;
        align-items: center;
        padding: 0.1rem 1rem;
        .mini-avatar {
          border-radius: 50%;
          width: 1rem;
          height: 1rem;
          display: inline-block;
        }
        .wxuser-nickname {
          margin-left: 0.4rem;
        }
      }
    }
  }
}
</style>
