<template>
  <div>
    <van-sticky offset-top="1.2rem">
      <div class="btns-wrapper">
        <van-button type="primary" size="small" icon="sign" @click="handleOpenBatchSignIn">批量签到</van-button>
        <van-button type="info" size="small" icon="completed" @click="handleOpenBatchLessonLogForm"> 批量上课</van-button>
        <van-button type="danger" size="small" icon="failure" @click="handleOpenBatchSignOut"> 批量签退</van-button>
      </div>
    </van-sticky>
    <div class="studentList">
      <van-cell v-for="(item,idx) in studentList" :key="idx" is-link center>
        <template #title>
          <span class="left">
            <van-checkbox v-model="item.checked" shape="square" /><img :src="item.photo" class="avatar" />
          </span>
          <span class="right">
            <div class="student_name" @click="handleShowDetail(item)">
              <span>{{ item.cnName }}</span>
              <van-tag v-if="item.boundedWxFlag" type="success">微信</van-tag>
            </div>
            <div class="class_hour">
              <span v-if="item.classHour && item.classHour.accountType === 0">
                <van-tag type="warning">课时</van-tag>
                [总:{{ item.classHour.totalClassHour }}/校:{{ item.classHour.preSubtractClassHour }}/用:{{ item.classHour.usedClassHour }}/余:<span
                  class="surplus">{{ item.classHour.surplusClassHour }}</span>]
              </span>
              <span v-if="item.classHour && item.classHour.accountType === 1">
                <van-tag type="warning">截止日</van-tag> [{{ item.classHour.expiryDate }}/用:{{ item.classHour.usedClassHour }}]
              </span>
            </div>
            <div class="btn_wrapper">
              <van-button type="primary" size="small" @click="handleOpenSignInForm(item)">签到</van-button>
              <van-button type="info" size="small" @click="handleOpenLessonLogForm(item)">上课</van-button>
              <van-button type="danger" size="small" @click="handleOpenSignOutForm(item)">签退</van-button>
            </div>
          </span>
        </template>
      </van-cell>
    </div>

    <SignInOutForm ref="signInOutForm" :clazz-id="clazz.id" />
    <BatchSignInOutForm ref="batchSignInOutForm" :clazz-id="clazz.id" />
    <LessonLogForm ref="lessonLogForm" :clazz-id="clazz.id" @reloadStudentList="reloadStudentList" />
    <BatchLessonLogForm ref="batchLessonLogForm" :clazz-id="clazz.id" @reloadStudentList="reloadStudentList" />
    <StudentDetail ref="studentDetail" />
  </div>
</template>
<script>
import eduApi from '@/api/edu'
import wxuser from '@/api/wxuser'

import SignInOutForm from './SignInOutForm.vue'
import BatchSignInOutForm from './BatchSignInOutForm.vue'
import LessonLogForm from './LessonLogForm.vue'
import BatchLessonLogForm from './BatchLessonLogForm.vue'

import StudentDetail from './StudentDetail.vue'

// 消息场景：课时消耗
const MSG_SCENE_CLASS_HOUR_CONSUME = 6

export default {
  components: {
    SignInOutForm,
    LessonLogForm,
    BatchSignInOutForm,
    BatchLessonLogForm,
    StudentDetail
  },
  data() {
    return {
      appId: '',
      clazz: {
        id: null,
        cnName: ''
      },
      studentList: [],
      canSendSignInMsg: false,
      canSendSignOutMsg: false,
      canSendClassHourConsumeMsg: false,
      pageTitle: ''
    }
  },
  created() {
    this.appId = this.$route.params.appId
    this.clazz.id = this.$route.params.id
    this.clazz.cnName = this.$route.query.cnName
    this.pageTitle = '【' + this.clazz.cnName + '】 学员列表'
    this.loadStudentList()
    document.title = '【' + this.clazz.cnName + '】信息'
  },
  mounted() {},
  methods: {
    reloadStudentList() {
      this.loadStudentList()
    },
    loadStudentList() {
      eduApi.studentList(this.clazz.id).then(res => {
        if (res.succ) {
          this.canSendSignInMsg = res.data.canSendSignInMsg
          this.canSendSignOutMsg = res.data.canSendSignOutMsg
          this.canSendClassHourConsumeMsg = res.data.canSendClassHourConsumeMsg
          this.studentList = res.data.items
          for (let i = 0; i < this.studentList.length; i++) {
            if (this.studentList[i].boundedWxUserList && this.studentList[i].boundedWxUserList.length > 0) {
              this.studentList[i].boundedWxFlag = true
            } else {
              this.studentList[i].boundedWxFlag = false
            }
            if (!this.studentList[i].photo) {
              this.studentList[i].photo = 'http://backend.c60rock.com/img/default_avatar_2.jpg?imageView2/1/w/80/h/80'
            }
          }
          // this.studentList = this.studentList.concat(res.data.items)
        }
      })
    },
    handleShowDetail(item) {
      console.log(item.id)
      this.$refs.studentDetail.open(item)
    },
    handleOpenBatchSignIn() {
      let selectedStudents = []
      for (let i = 0; i < this.studentList.length; i++) {
        let student = this.studentList[i]
        if (student.checked) {
          selectedStudents.push(student)
        }
      }

      if (selectedStudents.length === 0) {
        this.$toast('请选择需要签到的学员')
        return
      }
      this.$refs.batchSignInOutForm.openSignIn(selectedStudents, this.canSendSignInMsg)
    },
    handleOpenBatchSignOut() {
      let selectedStudents = []
      for (let i = 0; i < this.studentList.length; i++) {
        let student = this.studentList[i]
        if (student.checked) {
          selectedStudents.push(student)
        }
      }

      if (selectedStudents.length === 0) {
        this.$toast('请选择需要签退的学员')
        return
      }
      this.$refs.batchSignInOutForm.openSignOut(selectedStudents, this.canSendSignOutMsg)
    },
    handleOpenBatchLessonLogForm() {
      let selectedStudents = []
      for (let i = 0; i < this.studentList.length; i++) {
        let student = this.studentList[i]
        if (student.checked) {
          selectedStudents.push(student)
        }
      }

      if (selectedStudents.length === 0) {
        this.$toast('请选择需要上课的学员')
        return
      }
      for (let i = 0; i < selectedStudents.length; i++) {
        let student = selectedStudents[i]
        if (!student.classHour) {
          this.$toast('学员[' + student.cnName + ']没有设置课时账户')
          return false
        }
      }
      this.$refs.batchLessonLogForm.open(selectedStudents, this.canSendClassHourConsumeMsg)
    },
    // 单独签到
    handleOpenSignInForm(item) {
      this.$refs.signInOutForm.openSignIn(item, this.canSendSignInMsg)
    },
    // 单独签退
    handleOpenSignOutForm(item) {
      this.$refs.signInOutForm.openSignOut(item, this.canSendSignOutMsg)
    },
    handleOpenLessonLogForm(item) {
      this.$refs.lessonLogForm.open(item, this.canSendClassHourConsumeMsg)
    },

    toEdu() {
      this.$router.push(`/${this.appId}/edu`)
    }
  }
}
</script>
<style lang="less" scoped>
.van-sticky {
  div.btns-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0.2rem 1.4rem;
    background-color: #fff;
  }
}

/deep/ .van-cell__title {
  display: flex;
  align-items: center;
  .left {
    width: 2.2rem;
    display: flex;
    justify-content: space-between;
    .van-checkbox {
      //do nothing
    }
    img.avatar {
      width: 1.4rem;
      height: 1.4rem;
      border-radius: 50%;
    }
  }
  .right {
    flex: 3;
    margin-left: 0.2rem;
    .student_name {
      display: flex;
      align-items: center;
      font-size: 0.5rem;
      line-height: 0.7rem;
      > :first-child {
        margin-right: 0.2rem;
      }
    }
    .class_hour {
      .surplus {
        font-weight: 700;
        color: red;
      }
    }

    .btn_wrapper {
      display: flex;
      justify-content: space-around;
      .van-button {
        flex: 1;
        margin-right: 0.4rem;
      }
    }
  }
}
</style>
