<template>
  <div>
    <div class="schedule-box">
      <span class="label"> 排课时间：</span>
      <span class="value">
        <ul>
          <li v-for="(schedule,idx) of clazzScheduleList" :key="idx">
            <van-tag type="primary"> {{ schedule.weekday | formatWeekday }}</van-tag> {{ schedule.startTime }} ~{{ schedule.endTime }}
          </li>
        </ul>
      </span>
    </div>
    <div class="btn-wrapper">
      <van-button block type="primary" :disabled="clazzScheduleList.length == 0" @click="handleBeforeSendCourseNotice">发送上课提醒</van-button>
    </div>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="initDataList">
      <van-cell v-for="item in dataList" :key="item.id" :title="'上课时间：' + item.courseTimeDescn">
        <template #label>
          <div>提醒时间：{{ item.createTime }}</div>
        </template>
      </van-cell>
    </van-list>
  </div>
</template>
<script>
import eduApi from '@/api/edu'
export default {
  data() {
    return {
      studentId: 1,
      dataList: [],
      clazz: {
        id: ''
      },
      hasMsgTpl: false, // 是否有可用的消息模板
      clazzScheduleList: [],
      maxId: null,
      loading: false,
      finished: false
    }
  },
  created() {
    this.clazz.id = this.$route.params.id
    this.initData()
  },
  mounted() {},
  methods: {
    initData() {
      eduApi.clazzSchedule(this.clazz.id).then(res => {
        if (res.succ) {
          this.clazzScheduleList = res.data.items
          this.hasMsgTpl = res.data.hasMsgTpl
        }
      })
    },
    handleBeforeSendCourseNotice() {
      eduApi.beforeSendCourseNotice(this.clazz.id).then(res => {
        if (res.succ) {
          if (!res.data.canSendMsg) {
            this.$dialog.alert({
              title: '提示',
              message: res.data.msg,
              theme: 'round-button'
            })
          } else {
            this.$dialog
              .confirm({
                title: '发送上课提醒',
                message: res.data.msg
              })
              .then(() => {
                let data = {
                  clazzId: this.clazz.id,
                  scheduleId: res.data.scheduleId
                }
                eduApi.sendRemindLessonMsg(data).then(res => {
                  if (res.succ) {
                    // 清空列表，重置maxId后重新加载
                    this.maxId = null
                    this.dataList = []
                    this.initDataList()
                  }
                })
              })
            // .cancel(() => {
            //   // do nothing
            // })
          }
        }
      })
    },
    initDataList() {
      eduApi.courseNoticeList({ maxId: this.maxId, clazzId: this.clazz.id }).then(res => {
        if (res.succ) {
          let items = res.data.items
          if (items.length === 0) {
            this.finished = true
          } else {
            for (let i = 0; i < items.length; i++) {
              this.dataList.push(items[i])
            }
            this.loading = false
            // 将maxId设置为最后一条记录的id
            this.maxId = items[items.length - 1].id
          }
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.schedule-box {
  display: flex;
  padding: 0.2rem 0.4rem;
  .label {
    flex: 1;
    align-self: flex-start;
    font-size: 0.4rem;
    font-weight: 700;
    text-align: right;
    padding-top: 0.05rem;
    padding-right: 0.6rem;
  }
  .value {
    flex: 2;
    ul {
      li {
        line-height: 0.6rem;
        height: 0.6rem;
      }
    }
  }
}
.btn-wrapper {
  padding: 0 0.4rem 0.2rem;
}
</style>
